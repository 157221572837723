import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Heading from "../components/Heading";
import Content, { HTMLContent } from '../components/Content'
import TourRoll from "../components/TourRoll";

export const ToursPageTemplate = ({
  image,
  title,
  subtitle,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content

  return (
      <div className="content">
        <Heading title={title} image={image} subtitle={subtitle}/>
        <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="column is-10 is-offset-1">
                <PostContent content={content}/>
              </div>
              <div className="content">
                <TourRoll />
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}

ToursPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  intro: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,
}

const ToursPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ToursPageTemplate
        content={html}
        contentComponent={HTMLContent}
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        intro={frontmatter.intro}
        testimonials={frontmatter.testimonials}
      />
    </Layout>
  )
}

ToursPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ToursPage

export const ToursPageQuery = graphql`
  query ToursPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
